<template>
  <div>
    <a-button-group class="mb-2">
      <a-tooltip placement="top">
        <template slot="title">
          <span>Refresh Data</span>
        </template>
        <a-button @click="getAllData(true)">
          <a-icon type="sync" :spin="loading" class="text-success" />
        </a-button>
      </a-tooltip>
      <a-button @click="saveData" class="text-primary">
        <a-icon type="check" /> Simpan
      </a-button>
      <a-button @click="getDocumentPDF" class="text-warning">
        <a-icon type="file-pdf" /> Test Print
      </a-button>
        <a-button @click="getReset" class="text-primary">
          <a-icon type="issues-close" /> Reset Position
        </a-button>
    </a-button-group>
    <div class="row">
      <div
        id="paper"
        :style="{
          width: `${mmToPixels(propertyPaper.page_width)}px`,
          height: `${mmToPixels(propertyPaper.page_height)}px`,
        }"
        ref="paper"
        @mousemove="onMouseMove"
        @mouseup="onMouseUp"
        @mouseleave="onMouseUp"
      >
        <div
          v-for="(element, index) in draggableElements"
          id="draggable"
          :key="index"
          :ref="'draggable' + index"
          class="draggable"
          :style="{
            top: `${element.top}px`,
            left: `${element.left}px`,
            width: `${element.width}px`,
            cursor: draggingIndex === index ? 'grabbing' : 'grab',
            fontSize: '16px !important',
              fontFamily: element.font_name + ' !important',
              fontWeight: element.font_bold ? 'bold !important' : 'unset !important',
              fontStyle: element.font_italic ? 'italic !important' : 'unset !important',
              textDecoration: element.font_underline
                ? 'underline !important'
                : 'unset !important',
          }"
          @mousedown="onMouseDown($event, index)"
        >
          {{ element.name }}
          <!-- <div class="coordinates">
          x: {{ element.left.toFixed(0) }}px, y: {{ element.top.toFixed(0) }}px
        </div>
        <div class="coordinates">
          x: {{ pxToMm(element.left).toFixed(2) }}mm, y: {{ pxToMm(element.top).toFixed(2) }}mm
        </div> -->
        </div>
      </div>
      <!-- <button class="btn btn-outline-secondary" style="height: 50px;" @click="togglePanel">{{ isPanelExpanded ? 'Collapse' : 'Properties' }}</button> -->
      <!-- <div id="property-panel" :class="{ expanded: isPanelExpanded }">
      <div v-if="isPanelExpanded && selectedElementIndex !== null">
          <h3>Properties</h3>
          <p><strong>Element:</strong> {{ selectedElementIndex + 1 }}</p>
          <p><strong>Pixels:</strong></p>
          <p>X: {{ checkSelected('left') }}px</p>
          <p>Y: {{ checkSelected('top') }}px</p>
          <p><strong>Millimeters:</strong></p>
          <p>X: {{ pxToMm(checkSelected('left')).toFixed(2) }}mm</p>
          <p>Y: {{ pxToMm(checkSelected('left')).toFixed(2) }}mm</p>
        </div>
      </div> -->
      <a-card style="max-width: 300px;">
        <template slot="title">
          <label
            :style="{
              // fontSize: checkSelected('font_size') + ' !important',
              fontFamily: checkSelected('font_name') + ' !important',
              fontWeight: checkSelected('font_bold') ? 'bold !important' : 'unset !important',
              fontStyle: checkSelected('font_italic') ? 'italic !important' : 'unset !important',
              textDecoration: checkSelected('font_underline')
                ? 'underline !important'
                : 'unset !important',
            }"
            >{{
              checkSelected("name") === ""
                ? "Belum Memilih"
                : checkSelected("name")
            }}</label
          >
          <a-button-group class="pull-right">
            <a-button
              :class="
                checkSelected('font_bold')
                  ? 'btn btn-sm btn-outline-primary'
                  : 'btn btn-sm'
              "
              @click="selectedChanged('font_bold')"
            >
              <a-icon type="bold"></a-icon>
            </a-button>
            <a-button
              :class="
                checkSelected('font_italic')
                  ? 'btn btn-sm btn-outline-primary'
                  : 'btn btn-sm'
              "
              @click="selectedChanged('font_italic')"
            >
              <a-icon type="italic"></a-icon>
            </a-button>
            <a-button
              :class="
                checkSelected('font_underline')
                  ? 'btn btn-sm btn-outline-primary'
                  : 'btn btn-sm'
              "
              @click="selectedChanged('font_underline')"
            >
              <a-icon type="underline"></a-icon>
            </a-button>
          </a-button-group>
          <!-- <br>
          <a-button-group class="pull-right">
            <a-button
              :class="
                checkSelected('align') === 'Left'
                  ? 'btn btn-sm btn-outline-primary'
                  : 'btn btn-sm'
              "
              @click="selectedChanged('align', 'Left')"
            >
              <a-icon type="align-left"></a-icon>
            </a-button>
            <a-button
              :class="
                checkSelected('align') === 'Center'
                  ? 'btn btn-sm btn-outline-primary'
                  : 'btn btn-sm'
              "
              @click="selectedChanged('align', 'Center')"
            >
              <a-icon type="align-center"></a-icon>
            </a-button>
            <a-button
              :class="
                checkSelected('align') === 'Right'
                  ? 'btn btn-sm btn-outline-primary'
                  : 'btn btn-sm'
              "
              @click="selectedChanged('align', 'Right')"
            >
              <a-icon type="align-right"></a-icon>
            </a-button>
          </a-button-group> -->
        </template>
        <label>Point X (mm)</label>
        <a-input
          v-model="maskInput.left"
          :placeholder="pxToMm(checkSelected('left')).toFixed(2)"
          @input="selectedChanged('left')"
        ></a-input>
        <label class="mt-2">Point Y (mm)</label>
        <a-input
          v-model="maskInput.top"
          :placeholder="pxToMm(checkSelected('top')).toFixed(2)"
          @input="selectedChanged('top')"
        ></a-input>
        <label class="mt-2">Width</label>
        <a-input
          v-model="maskInput.width"
          :placeholder="checkSelected('width')"
          @input="selectedChanged('width')"
        ></a-input>
        <label class="mt-2">Font Size </label> <label class="text-secondary">(Def View: 16)</label>
        <div>
          <a-input
            v-model="maskInput.font_size"
            style="width: 70px;"
            :placeholder="checkSelected('font_size')"
            @input="selectedChanged('font_size')"
          ></a-input>
          <a-button style="width: 170px;" class="ml-1 btn btn-outline-primary" @click="appliedChangeFontSize">Apply Font Size to Editor View</a-button>
        </div>
        <label class="mt-2">Font Name</label>
        <v-select
            label="name"
            placeholder="Font Name"
            v-model="maskInput.font_name"
            :options="masterFont"
            :reduce="(tes) => tes.name"
            @input="selectedChanged('font_name')"
          class="mb-3"
          />
        <!-- <a-input
          v-model="maskInput.font_name"
          :placeholder="checkSelected('font_name')"
          @input="selectedChanged('font_name')"
        ></a-input> -->
        <!-- <label>Point Y</label>
          <a-input></a-input> -->
        <strong>Paper Settings</strong>
        <br>
        <label>Page Width</label>
        <a-input
          v-model="maskInput.page_width"
          :placeholder="mmToPixels(checkSelected('page_width')).toFixed(2)"
          @input="selectedChanged('page_width')"
        ></a-input>
        <label class="mt-2">Page Height</label>
        <a-input
          v-model="maskInput.page_height"
          :placeholder="mmToPixels(checkSelected('page_height')).toFixed(2)"
          @input="selectedChanged('page_height')"
        ></a-input>
        <label class="mt-2">Margin Top</label>
        <a-input
          v-model="maskInput.margin_top"
          :placeholder="mmToPixels(checkSelected('margin_top')).toFixed(2)"
          @input="selectedChanged('margin_top')"
        ></a-input>
        <label class="mt-2">Margin Left</label>
        <a-input
          v-model="maskInput.margin_left"
          :placeholder="mmToPixels(checkSelected('margin_left')).toFixed(2)"
          @input="selectedChanged('margin_left')"
        ></a-input>
        <a-button class="btn btn-outline-primary mt-2 pull-right" @click="changedPaper">Apply</a-button>
      </a-card>
    </div>
  </div>
</template>
<html lang="en"></html>
<script>

import * as lou from '@/services/data/lou'
import { Modal } from 'ant-design-vue'

export default {
  created() {
    this.getMaster()
    this.getAllData()
  },
  data() {
    return {
      propertyPaper: {
        page_width: 210,
        page_height: 150,
        margin_top: 10,
        margin_left: 10,
        unit: 'mm',
      },
      draggableElements: [
        // {
        //   field: 'title',
        //   name: 'Nomor Bilyet',
        //   top: 10,
        //   left: 10,
        //   point_x: 84.88,
        //   point_y: 45.5,
        //   width: 25,
        //   font_size: 8,
        //   font_name: 'Lato',
        //   font_bold: false,
        //   font_italic: false,
        //   font_underline: false,
        //   align: 'Left',
        //   type: 'Text',
        //   value: null,
        // },
      ],
      offset: { x: 10, y: 10 },
      draggingIndex: null,
      selectedIndex: null,
      isPanelExpanded: false, // Status for panel expansion
      dpi: 96, // Assuming 96 DPI for conversion
      marginTop: 10, // 10mm margin on top converted to pixels
      marginLeft: 10, // 10mm margin on left converted to pixels
      // componentKey: 0,
      response: [],
      loading: false,

      // Properties
      masterFont: [],
      maskInput: {
        page_width: 210,
        page_height: 150,
        margin_top: 10,
        margin_left: 10,
        unit: 'mm',
        top: '',
        left: '',
        width: '',
        font_size: 0,
        font_name: '',
        font_bold: false,
        font_italic: false,
        font_underline: false,
        align: '',
        type: '',
      },
      rules: {
        top: {
          length: 10000000,
        },
        left: {
          length: 10000000,
        },
        width: {
          length: 10000000,
        },
        font_size: {
          length: 10000000,
        },
        font_name: {
          length: 10000000,
        },
        font_style: {
          length: 10000000,
        },
        align: {
          length: 10000000,
        },
        type: {
          length: 10000000,
        },
      },
    }
  },
  methods: {
    changedPaper() {
      this.propertyPaper.page_width = this.maskInput.page_width
      this.propertyPaper.page_height = this.maskInput.page_height
      this.propertyPaper.margin_top = this.maskInput.margin_top
      this.propertyPaper.margin_left = this.maskInput.margin_left
    },
    async saveData() {
      var fd = {
        ...this.propertyPaper,
      }
      fd.details = []
      fd.page_width = this.maskInput.page_width
      fd.page_height = this.maskInput.page_height
      fd.margin_top = this.maskInput.margin_top
      fd.margin_left = this.maskInput.margin_left
      fd.unit = this.maskInput.unit
      this.draggableElements.forEach((element) => {
        var np = {
          ...element,
        }
        np.point_x = this.pxToMm(np.left).toFixed(2)
        np.point_y = this.pxToMm(np.top).toFixed(2)
        np.width = this.pxToMm(np.width).toFixed(2)
        delete np.left
        delete np.top
        fd.details.push(np)
      })
      var res = await lou.customUrlPost2('setting/konfigbilyet', fd, true, false, true)
      if (res) {
        this.getAllData()
      }
    },
    async getDocumentPDF() {
      var res = await lou.ngetDocumentPdf(
        'setting/konfigbilyet/print_test?print=value',
        false,
        false,
      )
      if (res) {
        // console.log("Success", response);
        const blob = new Blob([res], {
          type: 'application/pdf',
        })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.target = '_blank'
        // link.download = filename
        link.click()
        // const objectUrl = URL.createObjectURL(blob)
        // console.log('objectUrl', objectUrl)
        // this.amodal.ArrayBuffer = objectUrl
        URL.revokeObjectURL(blob)
        // setTimeout(() => {
        // this.componentKey += 1
        // this.amodalvisible = true
        // }, 500)
      }
    },
    async getReset() {
      Modal.confirm({
        title: 'Yakin?',
        content: 'Posisi yang direset tidak dapat diubah! Pertimbangkan lagi',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var res = await lou.customUrlGet2('setting/konfigbilyet/reset')
          if (res) {
            // this.masterFont = this.$g.clone(res.data)
            lou.shownotif('Info', 'Posisi berhasil direset!')
          }
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    async getMaster() {
      var res = await lou.customUrlGet2('setting/konfigbilyet/fonts')
      if (res) {
        this.masterFont = this.$g.clone(res.data)
      }
    },
    async getAllData() {
      this.loading = true
      var res = await lou.customUrlGet2('setting/konfigbilyet', true)
      if (res) {
        this.response = this.$g.clone(res.data)
        this.propertyPaper = this.$g.clone(res.data)
        this.maskInput.page_width = res.data.page_width
        this.maskInput.page_height = res.data.page_height
        this.maskInput.margin_top = res.data.margin_top
        this.maskInput.margin_left = res.data.margin_left
        this.maskInput.unit = res.data.unit
        this.draggableElements = []
        this.response.details.forEach((element) => {
          var sadf = {
            ...element,
            top: this.mmToPixels(element.point_y),
            left: this.mmToPixels(element.point_x),
            width: this.mmToPixels(element.width),
          }
          this.draggableElements.push(sadf)
        })

        this.loading = false
      } else {
        this.loading = false
      }
    },
    appliedChangeFontSize() {
      this.$refs['draggable' + this.selectedIndex][0].style.cssText = this.replaceFontSize(this.$refs['draggable' + this.selectedIndex][0].style.cssText, this.maskInput.font_size)
    },
    replaceFontSize(cssString, newSize) {
      // Use a regular expression to find the font-size property and replace its value
      return cssString.replace(/font-size:\s*\d+px\s*!important;/, `font-size: ${newSize}px !important;`)
    },
    selectedChanged(el, val) {
      console.log('this.$refs["draggable" + this.selectedIndex]', this.$refs['draggable' + this.selectedIndex][0].style)
      var mmtopx = ['top', 'left', 'width']
      var num = ['font_size']
      if (mmtopx.includes(el)) {
        this.draggableElements[this.selectedIndex][el] = this.mmToPixels(
          this.maskInput[el],
        )
      } else if (el === 'font_bold' || el === 'font_italic' || el === 'font_underline') {
        this.maskInput[el] = !this.maskInput[el]
        this.draggableElements[this.selectedIndex][el] = this.maskInput[el]
      } else if (num.includes(el)) {
        this.draggableElements[this.selectedIndex][el] = this.maskInput[el] !== '' ? parseInt(this.maskInput[el]) : this.maskInput[el]
      } else if (el === 'align') {
        this.draggableElements[this.selectedIndex][el] = val
        this.align(val)
      } else {
        this.draggableElements[this.selectedIndex][el] = this.maskInput[el]
      }
      console.log(el, this.draggableElements[this.selectedIndex])
      console.log(el, this.maskInput[el])
    },
    onMouseDown(event, index) {
      // console.log('index', index)
      this.draggingIndex = index
      this.selectedIndex = index
      const element = this.draggableElements[index]
      this.offset.x = event.clientX - element.left
      this.offset.y = event.clientY - element.top
      this.maskInput.left = this.pxToMm(element.left).toFixed(2)
      this.maskInput.top = this.pxToMm(element.top).toFixed(2)
      this.maskInput.width = this.pxToMm(element.width).toFixed(2)
      this.maskInput.font_size = element.font_size
      this.maskInput.font_name = element.font_name
      this.maskInput.font_bold = element.font_bold
      this.maskInput.font_italic = element.font_italic
      this.maskInput.font_underline = element.font_underline
      this.maskInput.align = element.align
      this.maskInput.type = element.type
    },
    onMouseMove(event) {
      // console.log('this.draggingIndex', this.draggingIndex)
      if (this.draggingIndex !== null) {
        const paperRect = this.$refs.paper.getBoundingClientRect()
        // const draggableRect = this.$refs['draggable' + this.draggingIndex].getBoundingClientRect()
        const newLeft = event.clientX - this.offset.x
        const newTop = event.clientY - this.offset.y
        // console.log("this.$refs['draggable' + this.draggingIndex][0].offsetWidth", this.$refs['draggable' + this.draggingIndex][0].offsetWidth)
        // console.log("this.$refs['draggable' + this.draggingIndex][0].offsetHeight", this.$refs['draggable' + this.draggingIndex][0].offsetHeight)
        if (
          newLeft >= this.maskInput.margin_top &&
          newLeft +
            this.$refs['draggable' + this.draggingIndex][0].offsetWidth <=
            paperRect.width
        ) {
          this.draggableElements[this.draggingIndex].left = newLeft
          this.maskInput.left = this.pxToMm(newLeft).toFixed(2)
          // console.log('this.draggableElements[this.draggingIndex].left', this.draggableElements[this.draggingIndex].left)
        }
        if (
          newTop >= this.maskInput.margin_left &&
          newTop +
            this.$refs['draggable' + this.draggingIndex][0].offsetHeight <=
            paperRect.height
        ) {
          this.draggableElements[this.draggingIndex].top = newTop
          this.maskInput.top = this.pxToMm(newTop).toFixed(2)
          // console.log('this.draggableElements[this.draggingIndex].top', this.draggableElements[this.draggingIndex].top)
        }
      }
    },
    align(position) {
      const paperRect = this.$refs.paper.getBoundingClientRect()
      switch (position) {
        case 'Left':
          this.draggableElements[this.selectedIndex].left = 0
          break
        case 'Center':
          this.draggableElements[this.selectedIndex].left = `${(paperRect.width - 60 - (this.draggableElements[this.selectedIndex].name.length * 2)) / 2}`
          break
        case 'Right':
          this.draggableElements[this.selectedIndex].left = `${paperRect.width - 60 - (this.draggableElements[this.selectedIndex].name.length * 2)}`
          break
      }
    },
    onMouseUp() {
      this.draggingIndex = null
      // console.log('this.draggableElements', this.draggableElements)
      // console.log(this.draggableElements[2].left, this.draggableElements[2].top)
    },
    checkSelected(retel) {
      if (this.selectedIndex !== null) {
        return this.draggableElements[this.selectedIndex][retel]
      } else {
        return ''
      }
    },
    pxToMm(px) {
      return px * 0.2645833333
    },
    mmToPixels(mm) {
      // 1 inch = 25.4 mm
      // pixels = mm * (dpi / 25.4)
      return mm * 3.7795275591
    },
    togglePanel() {
      this.isPanelExpanded = !this.isPanelExpanded
    },
  },
}
</script>

<style lang="scss" scoped>
.ant-card-body {
  padding: 24px !important;
}

#paper {
  // width: 210mm; /* A4 width */
  // height: 297mm; /* A4 height */
  // background-color: white;
  border: 1px solid;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
#property-panel {
  width: 300px;
  background-color: white;
  border-left: 1px solid #ccc;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  transform: translateX(0);
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000; /* Ensure panel is on top */
}

#property-panel button {
  width: 100%;
  padding: 10px;
  cursor: pointer;
}

#property-panel.expanded {
  transform: translateX(0);
}

#property-panel:not(.expanded) {
  transform: translateX(100%);
}

#draggable {
  // width: ;
  height: 20px;
  // background-color: lightblue;
  position: absolute;
}
</style>
